import React from 'react';
import { PaintingGalery } from '../components/PaintingGalery';
import { TextImage } from '../components';
import { Container, Layout, Spacer } from '../ui';
import { ANCHOR, TAnchor } from '../configs';

export default function IndexPage() {
	const inPageNaviItems = [
		{
			label: 'Über Doris',
			anchor: ANCHOR.START as TAnchor,
		},
		{
			label: 'Galerie',
			anchor: ANCHOR.GALERY as TAnchor,
		},
		{
			label: 'Kontakt',
			anchor: ANCHOR.CONTACT as TAnchor,
		},
	];
	return (
		<Layout title="Malerei Dori Art" description="Kunst von Doris Müller" inPageNaviItems={inPageNaviItems}>
			<Container anchor={ANCHOR.START as TAnchor}>
				<h1>Kunst von Doris Müller</h1>
				<TextImage name="DORIS_2" alt="Doris Müller steht vor einem ihrer Gemälde" align="RIGHT" />
				<p>
					Schon vor vielen Jahren habe ich meine Leidenschaft für die Malerei entdeckt. Mit großer
					Begeisterung und dem Wunsch, Neues zu entdecken, nahm ich an vielen Malkursen und Sommerakademien
					mit verschiedenen Dozenten, wie Lissy Porté, Igor Michajlov, Theodor Fischer, Günter Baus, Tanja
					Bach, Ramona Hoffmann, Rita Walle u.a., teil. So konnte ich mit Aquarell-, Acryl- und Ölfarbe, aber
					auch mit Pastellkreide experimentieren und meinen Malstil immer wieder verändern.
				</p>
				<p>
					Als langjähriges Mitglied des Kunstvereins Saar e.V. habe ich an zahlreichen Ausstellungen
					teilgenommen.
				</p>
				<p>
					Nachdem ich dem Kulturstammtisch Blieskastel e.V. beigetreten bin, konnte ich meine Werke bei der
					Kunstauktion 100x100 im Jahr 2019 sowie beim Kunst & Musikfest "WolfersArt" in Wolfersheim
					präsentieren.
				</p>
				<p>Malen ist für mich eine Möglichkeit, Zeit und Raum zu vergessen und mich selbst zu finden.</p>
				<Spacer variant={10} />
			</Container>
			<Container anchor={ANCHOR.GALERY as TAnchor}>
				<PaintingGalery />
				<Spacer variant={10} />
			</Container>
			<Container anchor={ANCHOR.CONTACT as TAnchor}>
				<h2>Kontakt</h2>
				<h4>info@malerei-dori-art.de</h4>
				<Spacer variant={10} />
			</Container>
		</Layout>
	);
}
